.App {
   margin:0;
   padding: 0;
   box-sizing: border-box;
   color: white;
   background:black;
}
.portfolio{
  width: 80%;
  /* border: 1px solid white; */
  margin:  auto;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  
}
.main{
  width: 45%;
  height: 93vh;
  position: relative;
  /* border: 1px solid white; */
 
}
.side{
  width: 45%;
  height: 94vh;
  margin: auto;
  /* border: 1px solid white; */
  overflow-y: hidden;
  overflow-y: scroll;
  
}
.side::-webkit-scrollbar{
  width: 0px;
}
.side::-webkit-scrollbar-thumb{
  background-color: transparent;
}
.side::-webkit-scrollbar-track {
  background-color: transparent; /* Hide the track */
}  
/* main container */
.maincontain {
  width:100%;
  margin: 15px 20px;
}

.first-img{
  width:100px;
  border-radius: 50px;
  border: 3px solid white;
}
.last-img{
  width: 50px;
  border-radius: 50px;
  border: 2px solid white;
}
.info-heading {
  line-height: 0.7;
}
.info-heading h2{
  font-size: 30px;
}
.info-heading h3{
  font-size: 22px;
  color: rgb(194, 194, 194);
}
.desc{
  width: 90%;
  text-align: left;
  font-size: 18px;
  text-wrap:pretty;
  color: lightgray;
  margin-bottom: 40px;
}
.lists {
  width: 90%;
 
}
.list {
  list-style:disc;
  margin-bottom: 40px;
  margin-left: -21px;
  /* border: 1px solid white; */
}
.list li{
  cursor: pointer;
  text-align: left;
  line-height:2;
  font-size: 18px;
  color:lightgray;
}
.list li a{
  text-decoration: none;
  color:lightgray;
}
.list li a:hover{
  color: rgba(109, 226, 216, 0.89);
  font-weight: 700;
}
.social-Links {
  display: flex;
  align-items: center;
  gap: 60px;
  position: absolute;
  bottom: 5px;
  margin-left: -10px;
  
}
.social {
  display: flex;
  align-items: center;
  gap:20px;
  margin-left: 0px;
}
.social a{
  text-decoration: none;
  color: white;
  word-spacing: 10px;
}
.icon{
  font-size: 16px;
  cursor: pointer;
  padding-left:0px;
}
.social span {
  font-size: 18px;
  cursor: pointer;
}
.sidecontain {
  width:99%;
  
}
/* for projecys  cont */
.projects-contain{
  width: 95%;
  padding-left:20px ;
  padding-bottom: 10px;
  /* border: 1px solid white; */
  margin:15px auto;
  background-color:rgb(57, 56, 56);
  line-height: 1.5;
  cursor: pointer;
  transform: scaleX(1);
  transition: 0.2s ease-in-out;
}
.projects-contain:hover{
 transform: scaleX(1.01);
  
}
.projects-contain p{
  padding-top: 1px;
}
.project-desc{
  line-height: 0.7;
}
.siding span{
  font-size: 16px;
}
/* for skills container */
.skillscontain{
  width:99%;
  /* border: 1px solid white; */
  margin: 50px auto;
}
.skills{
  width: 100%;
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  row-gap: 30px; */
  /*align-items: left;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
  row-gap: 30px;*/
}
.skills hr{
  margin:20px 0px;
}
.spaning{
  word-spacing:6px;
}
.skills-cont{
  width: 100px;
  height: 100px;
   display: flex;
   flex-direction: column;
   justify-content: center;
  align-items: center;
  border: 2px solid white;
  font-size: 20px;
  border-radius: 50px;
}
 
.skill-img{
  width: 100px;
  object-fit: contain;
}
.is{
  padding-left:10px;
}

/* for contact folder */
.contact-cont{
 width: 99%;
 /* border: 1px solid white; */
}
.contact{
  width:99%;
  margin: auto;
}
.cont-bin p{
  font-size: 18px;

}
form {
  display: flex;
  flex-direction: column;
  gap:20px;
 
}
form input {
  width: 375px;
  padding:10px;
}
form textarea{
   width: 400px;
}
.contact-icon{
  margin:10px auto;
 display: flex;
 justify-content:left;
 align-items: center;
 text-align: center;
 gap:20px;

}
.ic{
  display: flex;
 align-items: center;
 font-size: 20px;
 cursor: pointer;
}
.contact-icon span {
  display: flex;
 font-size: 18px;
 align-items: center;
 gap: 5px;
 cursor: pointer;
}
.contact-icon a{
  color:white;
  text-decoration: none;
}
.cnt-btn{
  width: 100px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
} 

@media(max-width:1024px){
  .portfolio{
    width: 90%;
    /* border: 1px solid white; */
    margin:  auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    
  }
  .main{
    width: 100%;
    height: auto;
  }
  .side{
    width: 100%;
    height: auto;
    

  }
  .lists{
   
  }
  .social-Links{
    display: none;
  }
  .skills{
    
   row-gap: 40px;
  }
  .projects-contain{
    width: 95%;
    padding-left:20px ;
    padding-bottom: 10px;
    /* border: 1px solid white; */
    margin:10px auto;
    background-color:rgb(57, 56, 56);
    line-height: 1.5;
    cursor: pointer;
  }
  .projects-contain:hover{
    width: 100%;
  }
  .project-desc{
    line-height: 0.7;
    font-size: 13px;
  }
  .contact{
    width: 100%;
  }
  form input {
    width: 300px;
    margin-left: 5px;
  }
  form textarea{
    width: 300px;
    margin-left: 5px;
  }

}

.skillbox{
  width:95%;
  border: 1px solid white;
  background: rgb(200, 199, 197);
  border-radius: 10px;
  cursor:pointer;
}
.skillbox h2{
  color: white;
  font-size: 24px;
  padding-left: 25px;
}
.skillcontain{
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.conti{
  width:150px;
 margin-top:5px;
 margin-bottom: 8px;
  /* border: 1px solid black; */
}
.conti p {
  text-align: center;
  font-size: 12px;
  color: rgb(138, 143, 142);
  font-weight: 500;
  padding-bottom: 20px;
}
.imi{
  width: 100%;
  display: flex;
  margin:15px 0px;
  justify-content: center;
}
.im{
  width:50px;
  background-color:rgb(235, 235, 235);
  color:white;
  border-radius: 10px;
  padding:10px;
  
}
.im:hover{
 background-color: rgba(109, 226, 216, 0.89);
}
.reac {
  font-size: 60px;
  color:rgb(235, 235, 235);
}
.reac:hover{
  color: rgba(109, 226, 216, 0.89);
}
/* liveweb working websites */
.liveweb{
  width: 95%;
  padding-left:20px ;
  padding-bottom: 10px;
  /* border: 1px solid white; */
  margin:15px auto;
  background-color:rgb(57, 56, 56);
  line-height: 1.5;
  cursor: pointer;
  border: 1px solid black;
  transform: scaleX(1);
  transition: 0.2s ease-in-out;
}



.bg-gradient {
  border-width: px;
  padding: 4; /* Adjust padding as needed */
  background-image: linear-gradient(to right, #232527, #1f1d20,#151f2c); /* Adjust colors as needed */
   border-top: none;
   color:rgb(180, 172, 172);
  transition: transform 1s ease-in-out; /* Assuming this value works in your setup */
  box-shadow: 3xl; /* Assuming this value works in your setup */
}/* From https://css.glass */
.glass {
  background: rgba(255, 255, 255, 0.14);
border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(0.2px);
-webkit-backdrop-filter: blur(0.2px);
border: 1px solid rgba(255, 255, 255, 0.23);
}
.glass1 {
  background: rgba(253, 253, 253, 0.14);
border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(0.2px);
-webkit-backdrop-filter: blur(0.2px);
border: 1px solid rgba(235, 229, 229, 0.23);
}